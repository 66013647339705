import app from "../main.js";
import * as i18n from "@/i18n";

const ROUTER_MAPPER = {
    base: '/',
    mode: 'hash',
    routes: [
        {
            path: '/',
            name: 'index',
            component: () => import('@/views/index'),
            meta: {
                title: i18n.t('router.text1'),
            },
        },
        {
            path: '/download',
            name: 'download',
            component: () => import('@/views/download'),
            meta: {
                title: i18n.t('router.text2'),
            },
        },
        {
            path: '/main',
            name: 'main',
            component: () => import('@/views/main'),
            redirect: '/index',
            meta: {
                title: i18n.t('router.text3'),
            },
            children: [
                // {
                //     path: '/index',
                //     name: 'index',
                //     component: () => import('@/views/main/components/adminMain'),
                //     meta: {
                //         title: '产品开发',
                //     },
                //     children: []
                // },
                // {
                //     path: '/debug',
                //     name: 'debug',
                //     component: () => import('@/views/debug/debug'),
                //     meta: {
                //         title: '调试',
                //     },
                //     children: []
                // },
                {
                    path: '/addProduct',
                    name: 'addProduct',
                    component: () => import('@/views/product/addProduct'),
                    meta: {
                        title: i18n.t('router.text4'),
                        parentPath: '/index'
                    },
                    children: []
                },
                {
                    path: '/deviceManagement',
                    name: 'deviceManagement',
                    component: () => import('@/views/product/deviceManagement'),
                    meta: {
                        title: i18n.t('router.text5'),
                        parentPath: '/index'
                    },
                    children: []
                },
                // {
                //     path: '/otaList',
                //     name: 'otaList',
                //     component: () => import('@/views/ota/otaList'),
                //     meta: {
                //         title: i18n.t('router.text6'),
                //         parentPath: '/index'
                //     },
                //     children: []
                // },
                {
                    path: '/product',
                    name: 'product',
                    component: () => import('@/views/product'),
                    redirect: '/product/basic',
                    meta: {
                        title: i18n.t('router.text3'),
                    },
                    children: [
                        {
                            path: '/product/basic',
                            name: 'basic',
                            component: () => import('@/views/product/views/basic'),
                            meta: {
                                title: i18n.t('router.text7'),
                                parentPath: '/index'
                            }
                        }
                    ]
                },
                {
                    path: '/deviceDebug',
                    name: 'deviceDebug',
                    component: () => import('@/views/debug/newDeviceDebug'),
                    meta: {
                        title: i18n.t('router.text8'),
                        parentPath: '/debug'
                    },
                    children: []

                },
                {
                    path: '/applicationDebug',
                    name: 'applicationDebug',
                    component: () => import('@/views/debug/newApplicationDebug'),
                    meta: {
                        title: i18n.t('router.text9'),
                        parentPath: '/debug'
                    },
                    children: []
                },
                // {
                //     path: '/empower',
                //     name: 'empower',
                //     component: () => import('@/views/empower'),
                //     meta: {
                //         title: '授权服务',
                //     },
                //     children: []
                // },
                {
                    path: '/empowerMsg',
                    name: 'empowerMsg',
                    component: () => import('@/views/empower/empowerMsg'),
                    meta: {
                        title: i18n.t('router.text10'),
                        parentPath: '/empower'
                    },
                    children: []
                },
                // {
                //     path: '/dashboard',
                //     name: 'dashboard',
                //     component: () => import('@/views/dashboard/index'),
                //     meta: {
                //         title: '厂测数据',
                //         parentPath: '/dashboard'
                //     },
                //     children: []
                // },
                // {
                //     path: '/deviceStatistics',
                //     name: 'deviceStatistics',
                //     component: () => import('@/views/deviceStatistics/index'),
                //     meta: {
                //         title: '上下线统计',
                //         parentPath: '/deviceStatistics'
                //     },
                //     children: []
                // },
                // {
                //     path: '/afterSales',
                //     name: 'afterSales',
                //     component: () => import('@/views/maintain/afterSales'),
                //     meta: {
                //         title: '售后运维',
                //     },
                //     children: []
                // },
                {
                    path: '/deviceLog',
                    name: 'deviceLog',
                    component: () => import('@/views/maintain/deviceLog'),
                    meta: {
                        title: i18n.t('router.text11'),
                        parentPath: '/index'
                    },
                    children: []
                },
                // {
                //     path: '/ota',
                //     name: 'ota',
                //     component: () => import('@/views/maintain/ota'),
                //     meta: {
                //         title: 'OTA升级',
                //     },
                //     children: []
                // },
                {
                    path: '/releaseMsg',
                    name: 'releaseMsg',
                    component: () => import('@/views/maintain/releaseMsg'),
                    meta: {
                        title: i18n.t('router.text17'),
                        parentPath: '/ota'
                    },
                    children: []
                },
                {
                    path: '/firmwareTest',
                    name: 'firmwareTest',
                    component: () => import('@/views/maintain/firmwareTest'),
                    meta: {
                        title: i18n.t('router.text12'),
                        parentPath: '/ota'
                    },
                    children: []
                },
                {
                    path: '/firmwareMsg',
                    name: 'firmwareMsg',
                    component: () => import('@/views/maintain/firmwareMsg'),
                    meta: {
                        title: i18n.t('router.text13'),
                        parentPath: '/ota'
                    },
                    children: []
                },

            ]
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/login'),
            meta: {
                title: i18n.t('router.text1'),
            },
        },
        {
            path: '/protocol',
            name: 'protocol',
            component: () => import('@/views/protocol'),
            meta: {
                title: i18n.t('router.text1'),
            },
        },
        {
            path: '/help',
            name: 'help',
            component: () => import('@/views/help'),
            meta: {
                title: i18n.t('router.text1'),
            },
        },
        {
            path: '*',
            name: 'transition',
            component: () => import('@/views/main/transition'),
            meta: {
                title: i18n.t('router.text1'),
            },
        },
        {
            path: '/setting',
            name: 'setting',
            component: () => import('@/views/setting'),

            //获取vuex中存储的第一个首页用户菜单为重定向路由
            // redirect: to => app.$store.getters['login/getSettingMenuRedirect'],
            meta: {
                title: i18n.t('router.text3'),
            },
            children: [
                {
                    path: '/authentication',
                    name: 'authentication',
                    component: () => import('@/views/authentication'),
                    meta: {
                        title: i18n.t('router.text14'),
                        parentPath: '/setting/enterpriseInfo'
                    },
                    children: []
                }
            ]
        },
        {
            path: '/notice',
            name: 'notice',
            component: () => import('@/views/notice/notice'),
            meta: {
                title: i18n.t('router.text15'),
            },
            children: []
        },
        {
            path: '/destroy',
            name: 'destroy',
            component: () => import('@/views/destroy'),
            meta: {
                title: i18n.t('router.text16')
            },
        },

    ],
}

export default ROUTER_MAPPER;
